/* eslint-disable */
import axios from "axios";
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useState } from "react";
import { Search } from "react-feather";
import { UserContext } from "src/config/userContext";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input
} from "@doar/components";

import { StyledForm } from "./style";
import "./styles.css";

// import { StyledDropDown } from "src/layouts/layout-02/content-header/style";

interface IProps {
    getInput?: any;
}

const ContentSearch: React.FC<IProps> = (props) => {
    const { user } = useContext(UserContext);
    const { getInput } = props;
    function handleSubmit(e: any) {
        e.preventDefault();
        
    }
    function handleChange(e: any) {
        getInput(e.target.value);
    }

    return (
        <>
            <StyledForm onSubmit={handleSubmit} className="search-input-header">
                <Search strokeWidth="2.8px" size={20} />
                <Input
                    type="text"
                    id="search-new"
                    name="search-new"
                    placeholder="Search..."
                    autocomplete="off"
                    onChange={handleChange}
                />
            </StyledForm>
        </>
    );
};

export default ContentSearch;
