/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext, useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import { CustomerContext, ShowSidebarContext, UserContext } from "src/config/userContext";

import { Avatar, AvatarInitial, Badge, DropdownMenu, DropdownToggle, Text } from "@doar/components";
// import logoRuedita1 from "@doar/shared/images/logo-ruedita.png";
import logoRuedita from "@doar/shared/images/logo/logo-ruedita-fondo-negro.png";

import { StyledMedia, StyledWrap } from "./style";
import "./styles.css";

const SidebarHeader: FC = (props) => {
  const { user } = useContext(UserContext);
  const { showSidebar, showSidebarFixed } = useContext(ShowSidebarContext);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const [show, setShow] = useState(true);
  const [showAdminBadge, setShowAdminBadge] = useState(false);

  function handleClick(customer1: any) {
    customerUpdate(customer1);
    const stringCust = JSON.stringify(customer1);
    sessionStorage.setItem("customerSelected", stringCust);
    setShow(false);
  }

  function handleShow() {
    setShow(true);
  }
  useEffect(() => {
    setShow(false);
  }, [showSidebar]);

  useEffect(() => {
    if (user?.user?.role === "ADMIN") {
      setShowAdminBadge(true);
    } else {
      setShowAdminBadge(false);
    }
  }, [user]);

  return (
    <StyledWrap direction="down">
      <DropdownToggle variant="texted" className={`dropdown-toggle ${showSidebar ? "show" : ""}`}>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleShow()}
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
          }}
        >
          <StyledMedia>
            {showAdminBadge ? (
              <Avatar isAdmin={showAdminBadge}>
                <div style={{ width: "40px", height: "40px", marginLeft: "-6px" }}>
                  <img
                    src={logoRuedita}
                    alt="Loading..."
                    style={{
                      width: "30px",
                      height: "30px",
                      // esto hace que gire el loguito
                      //  animation: "spin 5s linear infinite"
                    }}
                  />
                </div>
                {/* <AvatarInitial>{user?.user?.name?.charAt(0)}</AvatarInitial> */}
              </Avatar>
            ) : (
              <Avatar isAdmin={!showAdminBadge} size="sm" mr="8px">
                <AvatarInitial>{user?.user?.name?.charAt(0)}</AvatarInitial>
              </Avatar>
            )}
            {(showSidebar || !showSidebarFixed) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  marginLeft: "5px",
                }}
              >
                <Text as="span" color="text" fontWeight={600} fontSize={12}>
                  <div style={showAdminBadge ? { marginLeft: "5px" } : {}}>{user?.user?.name}</div>
                </Text>
                <Text as="span" color="text" fontSize={12}>
                  <div style={showAdminBadge ? { marginLeft: "5px" } : {}}>{customer?.name ? customer?.name : customer?.id}</div>
                  {/* {showAdminBadge && <Badge color="warning"> Admin</Badge>} */}
                </Text>
              </div>
            )}
          </StyledMedia>
          {(showSidebar || !showSidebarFixed) && (
            <span
              style={{
                position: "absolute",
                right: "3%",
                top: "35%",
              }}
            >
              <ChevronDown className="chevronDown" size={18} />
            </span>
          )}
        </div>
      </DropdownToggle>

      {show && (
        <DropdownMenu>
          <strong
            style={{
              fontWeight: 400,
              color: "#8392a5",
              fontSize: "0.9em",
            }}
          >
            Cuentas
          </strong>
          <div style={{ marginTop: "5px" }}>
            {user?.customers?.map((customer2: any) => {
              return (
                <button
                  className={`buttonSelect ${customer2.id === customer.id ? "selected" : ""}`}
                  key={customer2.id}
                  type="button"
                  onClick={() => {
                    handleClick(customer2);
                  }}
                  style={{
                    margin: "0",
                    padding: "5px 8px",
                    fontSize: "13px",
                    color: "black",
                    background: "transparent",
                    border: "none",
                    display: "block",
                  }}
                >
                  {customer2?.name}
                </button>
              );
            })}
          </div>
        </DropdownMenu>
      )}
    </StyledWrap>
  );
};

export default SidebarHeader;
