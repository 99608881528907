/* eslint-disable */
import React, { useContext } from "react";
// import { Avatar, AvatarInitial, Heading } from "@doar/components";
// import { ShowSidebarContext, UserContext } from "src/config/userContext";
// import Alerts from "./alerts";
import LoggedinUser from "./loggedin-user";
import { StyledUser, StyledAvatarWrap } from "./style";

const AsideUser: React.FC = () => {
    return (
        <StyledUser className="aside-user">
            <LoggedinUser />
        </StyledUser>
    );
};

export default AsideUser;
