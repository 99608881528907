import React from 'react';
import { IconProps } from 'types';

const ActivityIcon: React.FC<IconProps> = ({color}) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon/Outline/Activities">
<g id="favorite-chart">
<path id="Vector" d="M10.8334 18.9584H7.50008C2.97508 18.9584 1.04175 17.025 1.04175 12.5V7.50002C1.04175 2.97502 2.97508 1.04169 7.50008 1.04169H12.5001C17.0251 1.04169 18.9584 2.97502 18.9584 7.50002V10.8334C18.9584 11.175 18.6751 11.4584 18.3334 11.4584C17.9917 11.4584 17.7084 11.175 17.7084 10.8334V7.50002C17.7084 3.65835 16.3417 2.29169 12.5001 2.29169H7.50008C3.65841 2.29169 2.29175 3.65835 2.29175 7.50002V12.5C2.29175 16.3417 3.65841 17.7084 7.50008 17.7084H10.8334C11.1751 17.7084 11.4584 17.9917 11.4584 18.3334C11.4584 18.675 11.1751 18.9584 10.8334 18.9584Z" fill={color}/>
<path id="Vector_2" d="M6.10849 12.7C5.97516 12.7 5.84183 12.6583 5.72517 12.5667C5.45017 12.3583 5.40018 11.9667 5.60851 11.6917L7.59183 9.11668C7.83349 8.80834 8.17517 8.60834 8.56684 8.55834C8.9585 8.50834 9.34183 8.61668 9.65016 8.85834L11.1752 10.0583C11.2335 10.1083 11.2918 10.1 11.3335 10.1C11.3668 10.1 11.4252 10.0833 11.4752 10.0167L13.4002 7.53333C13.6085 7.25833 14.0002 7.20834 14.2752 7.42501C14.5502 7.63334 14.6002 8.02501 14.3835 8.30001L12.4585 10.7833C12.2168 11.0917 11.8752 11.2917 11.4835 11.3333C11.1002 11.3833 10.7085 11.275 10.4085 11.0333L8.88351 9.83334C8.82517 9.78334 8.75851 9.78334 8.72517 9.79167C8.69184 9.79167 8.63349 9.80834 8.58349 9.87501L6.60018 12.45C6.48352 12.6167 6.30016 12.7 6.10849 12.7Z" fill={color}/>
<path id="Vector_3" d="M16.8834 18.9583C16.5917 18.9583 16.2167 18.8667 15.775 18.6L15.5667 18.475C15.5084 18.4417 15.3334 18.4417 15.275 18.475L15.0667 18.6C14.1084 19.175 13.5001 18.9333 13.2334 18.7333C12.9584 18.5333 12.5334 18.0333 12.7834 16.9333L12.8251 16.7583C12.8417 16.6917 12.7917 16.5333 12.75 16.4833L12.4584 16.1917C11.9667 15.6917 11.775 15.1083 11.9417 14.5833C12.1084 14.0667 12.6001 13.7 13.2917 13.5833L13.6084 13.5333C13.6667 13.5167 13.7834 13.4333 13.8167 13.375L14.05 12.9C14.375 12.2417 14.8751 11.8667 15.4251 11.8667C15.9751 11.8667 16.4751 12.2417 16.8001 12.9L17.0334 13.3667C17.0667 13.425 17.1834 13.5083 17.2417 13.525L17.5584 13.575C18.2501 13.6917 18.7417 14.0583 18.9084 14.575C19.0751 15.0917 18.8917 15.675 18.3917 16.1833L18.1 16.475C18.0584 16.525 18.0084 16.6833 18.0251 16.75L18.0667 16.925C18.3167 18.025 17.8917 18.525 17.6167 18.725C17.4667 18.8417 17.2251 18.9583 16.8834 18.9583ZM15.4084 13.125C15.4 13.1333 15.2834 13.2167 15.1667 13.4583L14.9334 13.9333C14.7334 14.3417 14.2584 14.6917 13.8167 14.7667L13.5001 14.8167C13.2334 14.8583 13.1417 14.95 13.1334 14.9667C13.1334 14.9833 13.1584 15.1167 13.35 15.3083L13.6417 15.6C13.9834 15.95 14.1584 16.55 14.05 17.025L14.0084 17.2C13.9334 17.525 13.9667 17.6667 13.9834 17.7167C14.0084 17.7 14.15 17.6833 14.425 17.5167L14.6334 17.3917C15.0917 17.1167 15.7501 17.1167 16.2084 17.3917L16.4167 17.5167C16.7584 17.725 16.9001 17.7 16.9084 17.7C16.8751 17.7 16.9167 17.5333 16.8417 17.2L16.8001 17.025C16.6917 16.5417 16.8667 15.95 17.2084 15.6L17.5 15.3083C17.6917 15.1167 17.7167 14.9833 17.7167 14.9583C17.7084 14.9417 17.6167 14.8583 17.3501 14.8083L17.0334 14.7583C16.5834 14.6833 16.1167 14.3333 15.9167 13.925L15.6834 13.4583C15.5501 13.2083 15.4334 13.1333 15.4084 13.125Z" fill={color}/>
</g>
</g>
</svg>

);

export default ActivityIcon;