/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { device, themeGet, tinycolor, css } from "@doar/shared/styled";

export const StyledHeader = styled.div`
  background-color: black !important;
  height: 55px;
  // margin-top: 100px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet("colors.border")};
  padding-left: 65px;
  ${device.large} {
    height: 60px;
    padding: 0 25px;
  }
  .nav {
    position: absolute;
    right: 10px;
    &-link {
      &:not(:first-of-type) {
        margin-left: 10px;
      }
      line-height: 1;
      padding: 0;
      color: white;
      svg {
        width: 18px;
        height: 18px;
        ${device.large} {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  ${(props) =>
    props.theme.name !== "dark" &&
    css`
      background-color: #fff;
    `}
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: ${themeGet("colors.gray900")};
    `}
`;
export const StyledDropDown = styled.div`
  z-index: 999999999;
  overflow-y: scroll;
  max-height: 70vh;

  width: 100%;
  position: absolute;
  background-color: white;
  padding: 15px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border-bottom: 1px solid lightgray;
  .itemSelect:hover {
    cursor: pointer;
    background-color: rgb(0 0 0 / 5%);
  }
  // margin-left: 15px;
  // margin-right: 15px;
  // border-radius:0.5rem;
`;
