import './style.css';
/* eslint-disable import/order */
import { version } from '../../../../../../package.json';

/* eslint-disable */
import cn from "classnames";
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Eye,
  Globe,
  Grid,
  List,
  MessageCircle,
  MessageSquare,
  PieChart,
  Settings,
  Smile,
  Truck,
} from "react-feather";
import { useTranslation } from "react-i18next";
import {
  CustomerContext,
  ShowSidebarContext,
  UserContext
} from "src/config/userContext";

import { NavbarAside } from "@doar/components";
import { grid } from "@doar/shared";
// import { asideMenuData } from "@doar/shared/data";
import { IMenu, ISize, ISubmenu } from "@doar/shared/types";

import ActivityIcon from "../../../../../shared/icons/activity-icon";
import CallsIcon from "../../../../../shared/icons/calls-icon";
import ChannelSetupIcon from "../../../../../shared/icons/channelsetup-icon";
import ChatIcon from "../../../../../shared/icons/chat-icon";
import DashboardIcon from "../../../../../shared/icons/dashboard-icon";
import IntegrationsIcon from "../../../../../shared/icons/integrations-icon";
import LiveCallsIcon from "../../../../../shared/icons/live-calls-icon";
import SettingsIcon from "../../../../../shared/icons/settings-icon";
import VenuesIcon from "../../../../../shared/icons/venues-icon";
import AsideUser from "../../../components/aside-layout/user";
import Scrollbar from "../../../components/scrollbar";
import { useWindowSize } from "../../../hooks";
import Header from "../header";
import {
  StyledAside,
  StyledBackdrop,
  StyledBody,
  StyledBodyInner
} from "./style";

type TMaxText = "enter" | "leave";

interface IProps {
  layout?: "minimize";
  sidebarLayout?: 1 | 2;
}

const Aisde: React.FC<IProps> = ({ layout, sidebarLayout }) => {
  const [minimize, setMinimize] = useState(layout === "minimize");
  const [maximize, setMaximize] = useState(false);
  const [menuUtil, setMenuUtil] = useState<IMenu[]>([]);
  const { user } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);
  const { i18n, t } = useTranslation();

  const [show, setShow] = useState(false);
  const size: ISize = useWindowSize();
  const maximized = useRef(false);
  const mdMinimize = useRef(true);

  const { updateSidebar, showSidebar } = useContext(ShowSidebarContext);
  updateSidebar(maximize, minimize);

  const minimizeHandler = () => {
    setMinimize((prev) => !prev);
  };

  const displayHandler = () => {
    setMinimize(false);
    setShow((prev) => !prev);
  };

  useEffect(() => {
    if (!size.width) return;
    if (size.width > 991 && size.width < 1200 && !show && layout !== "minimize") {
      setMinimize(true);
    }
    if (size.width >= 1200 && maximized.current === false && layout !== "minimize") {
      setMinimize(false);
      maximized.current = true;
    }
    if (size.width <= 1199) {
      maximized.current = false;
    }
    if (size.width <= 991) {
      setMinimize(false);
    }
  }, [size.width, show, layout]);

  useEffect(() => {
    let partnerSubmenu: ISubmenu[] = [];
    switch (customer?.id) {
      case "dr-smile":
        partnerSubmenu.push({
          id: 78,
          label: "Dr Smile",
          url: "/dr-smile",
          Icon: Smile,
        });
        break;
      case "ambulancias-domingo":
        partnerSubmenu.push({
          id: 79,
          label: "Ambulancias Domingo",
          url: "/ambulancias-domingo",
          Icon: Truck,
        });
        break;
      case "fernandez-vega":
        partnerSubmenu.push({
          id: 80,
          label: "Fernandez Vega",
          url: "/fernandez-vega",
          Icon: Eye,
        });
        break;
      case "aythen":
        partnerSubmenu.push({
          id: 81,
          label: "Aythen",
          url: "/aythen",
          Icon: Globe,
        });
        break;

      default:
        break;
    }

    const adminMenu: IMenu[] = [
      {
        id: 11,
        label: "Dashboard",
        url: "/",
        Icon: DashboardIcon,
      },
      {
        id: 44,
        label: t("venues"),
        url: "/locales",
        Icon: VenuesIcon,
      },
      // {
      //   id: 45,
      //   label: t("products"),
      //   url: "/products",
      //   Icon: ProductsIcon,
      // },
      {
        id: 22,
        label: t("channel_setup"),
        url: "/channels",
        Icon: ChannelSetupIcon,
      },
      {
        id: 2,
        label: t("activity"),
        url: "#",
        Icon: ActivityIcon,
        submenu: [
          {
            id: 67,
            // Icon: CallsIcon,
            label: t("calls"),
            url: "/conversations",
          },
          {
            id: 66,
            // Icon: ChatIcon,

            label: t("chats"),
            url: "/chats",
          },
          {
            id: 68,
            // Icon: LiveCallsIcon,

            label: t("live_calls"),
            url: "/live-calls",
          },
          // {
          //   id: 71,
          //   // Icon: RequestIcon,

          //   label: t("requests"),
          //   url: "/requests",
          // },
          // {
          //   id: 70,
          //   // Icon: SurveyIcon,
          //   label: t("surveys"),
          //   url: "/surveys",
          // },
        ],
      },
      // {
      //   id: 55,
      //   label: t("clients"),
      //   url: "/clients",
      //   Icon: ClientsIcon,
      // },
      // {
      //   id: 56,
      //   label: t("campaigns"),
      //   url: "/settingss",
      //   Icon: CampaignsIcon,
      // },
      {
        id: 57,
        label: t("integrations"),
        url: "/integrations",
        Icon: IntegrationsIcon,
      },
      {
        id: 68,
        label: t("account_settings"),
        url: "/settings",
        Icon: SettingsIcon,
      },
      
      // {
      //   id: 69,
      //   label: t("billing"),
      //   url: "/billing",
      //   Icon: BillingIcon,
      // },
      // {
      //   id: 98,
      //   label: t("support"),
      //   url: "/support",
      //   Icon: SupportIcon,
      // },
    ];
    const clientMenu: IMenu[] = [
      // {
      //   id: 11,
      //   label: "Dashboard",
      //   url: "/",
      //   Icon: DashboardIcon,
      // },
      // {
      //   id: 44,
      //   label: t("venues"),
      //   url: "/locales",
      //   Icon: VenuesIcon,
      // },
      // {
      //   id: 45,
      //   label: t("products"),
      //   url: "/products",
      //   Icon: ProductsIcon,
      // },
      // {
      //   id: 22,
      //   label: t("channel_setup"),
      //   url: "/channels",
      //   Icon: ChannelSetupIcon,
      // },
      {
        id: 2,
        label: t("activity"),
        url: "#",
        Icon: ActivityIcon,
        submenu: [
          {
            id: 67,
            Icon: CallsIcon,
            label: t("calls"),
            url: "/conversations",
          },
          {
            id: 66,
            Icon: ChatIcon,

            label: t("chats"),
            url: "/chats",
          },
          {
            id: 68,
            Icon: LiveCallsIcon,

            label: t("live_calls"),
            url: "/live-calls",
          },
          
          // {
          //   id: 71,
          // Icon: RequestIcon,

          //   label: t("requests"),
          //   url: "/requests",
          // },
          // {
          //   id: 70,
          //   label: t("surveys"),
          //   url: "/surveys",
          // },
        ],
      },
      // {
      //   id: 55,
      //   label: t("clients"),
      //   url: "/clients",
      //   Icon: ClientsIcon,
      // },
      // {
      //   id: 56,
      //   label: t("campaigns"),
      //   url: "/settingss",
      //   Icon: CampaignsIcon,
      // },
      {
        id: 57,
        label: t("integrations"),
        url: "/integrations",
        Icon: IntegrationsIcon,
      },
      {
        id: 68,
        label: t("account_settings"),
        url: "/settings",
        Icon: SettingsIcon,
      },
      // {
      //   id: 69,
      //   label: t("billing"),
      //   url: "/billing",
      //   Icon: BillingIcon,
      // },
      // {
      //   id: 98,
      //   label: t("support"),
      //   url: "/support",
      //   Icon: SupportIcon,
      // },
    ];
    const hotelClientMenu: IMenu[] = [
      // {
      //   id: 11,
      //   label: "Dashboard",
      //   url: "/",
      //   Icon: DashboardIcon,
      // },
      // {
      //   id: 44,
      //   label: t("venues"),
      //   url: "/locales",
      //   Icon: VenuesIcon,
      // },
      // {
      //   id: 45,
      //   label: t("products"),
      //   url: "/products",
      //   Icon: ProductsIcon,
      // },
      // {
      //   id: 22,
      //   label: t("channel_setup"),
      //   url: "/channels",
      //   Icon: ChannelSetupIcon,
      // },
      {
        id: 2,
        label: t("activity"),
        url: "#",
        Icon: ActivityIcon,
        submenu: [
          // {
          //   id: 67,
          //   Icon: CallsIcon,
          //   label: t("calls"),
          //   url: "/conversations",
          // },
          {
            id: 66,
            Icon: ChatIcon,

            label: t("chats"),
            url: "/chats",
          },
          // {
          //   id: 68,
          //   Icon: LiveCallsIcon,

          //   label: t("live_calls"),
          //   url: "/live-calls",
          // },
          // {
          //   id: 71,
          // Icon: RequestIcon,

          //   label: t("requests"),
          //   url: "/requests",
          // },
          // {
          //   id: 70,
          //   label: t("surveys"),
          //   url: "/surveys",
          // },
        ],
      },
      // {
      //   id: 55,
      //   label: t("clients"),
      //   url: "/clients",
      //   Icon: ClientsIcon,
      // },
      // {
      //   id: 56,
      //   label: t("campaigns"),
      //   url: "/settingss",
      //   Icon: CampaignsIcon,
      // },
      {
        id: 57,
        label: t("integrations"),
        url: "/integrations",
        Icon: IntegrationsIcon,
      },
      {
        id: 68,
        label: t("account_settings"),
        url: "/settings",
        Icon: SettingsIcon,
      },
      // {
      //   id: 69,
      //   label: t("billing"),
      //   url: "/billing",
      //   Icon: BillingIcon,
      // },
      // {
      //   id: 98,
      //   label: t("support"),
      //   url: "/support",
      //   Icon: SupportIcon,
      // },
    ];

    // const clientMenu: IMenu[] = [
    //   {
    //     id: 1,
    //     label: t("general"),
    //     url: "/",
    //     Icon: PieChart,

    //     submenu: [
    //       // {
    //       //   id: 44,
    //       //   label: "Locales",
    //       //   url: "/locales",
    //       //   Icon: Home,
    //       // },

    
    //       {
    //         id: 56,
    //         label: t("settings"),
    //         url: "/settings",
    //         Icon: Settings,
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     label: t("conversations"),
    //     url: "#",
    //     submenu: [
    //       {
    //         id: 67,
    //         label: t("calls"),
    //         url: "/conversations",
    //         Icon: MessageSquare,
    //       },
    //       {
    //         id: 66,
    //         label: t("chats"),
    //         url: "/chats",
    //         Icon: MessageCircle,
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     label: t("integrations"),
    //     url: "/integrations",
    //     submenu: [
    //       {
    //         id: 67,
    //         label: t("marketplace"),
    //         url: "/marketplace",
    //         Icon: Grid,
    //       },
    //       {
    //         id: 66,
    //         label: t("installed"),
    //         url: "/integrations",
    //         Icon: List,
    //       },
    //     ],
    //   },
    // ];
    const partnerMenu: IMenu[] = [
      {
        id: 1,
        label: t("general"),
        url: "/",
        Icon: PieChart,

        submenu: [
          // {
          //     id: 33,
          //     label: "Conversaciones",
          //     url: "/conversations",
          //     Icon: MessageSquare,
          // },
          // {
          //     id: 44,
          //     label: "Integraciones",
          //     url: "/integrations",
          //     Icon: Grid,
          // },
          // {
          //   id: 44,
          //   label: "Locales",
          //   url: "/locales",
          //   Icon: Home,
          // },
          {
            id: 56,
            label: t("settings"),
            url: "/settings",
            Icon: Settings,
          },
        ],
      },
      {
        id: 2,
        label: t("conversations"),
        url: "#",
        submenu: [
          {
            id: 67,
            label: t("calls"),
            url: "/conversations",
            Icon: MessageSquare,
          },
          {
            id: 66,
            label: t("chats"),
            url: "/chats",
            Icon: MessageCircle,
          },
        ],
      },
      {
        id: 3,
        label: t("integrations"),
        url: "/integrations",
        submenu: [
          {
            id: 67,
            label: t("marketplace"),
            url: "/marketplace",
            Icon: Grid,
          },
          {
            id: 66,
            label: t("installed"),
            url: "/integrations",
            Icon: List,
          },
        ],
      },
      {
        id: 4,
        label: t("demos"),
        url: "#",
        submenu: partnerSubmenu,
      },
    ];

    let menu: IMenu[];

    if (user?.user?.role === "ADMIN") {
      menu = adminMenu;
    } else if (user?.user?.role === "PARTNER") {
      menu = partnerMenu;
    } else {
      // Hardcode for hotel clients
      if (['8Rx43zVQZqWyzW7c2GWTvZyuw503', 'bLVBudqn6eQo24Z7ZF85AVLdjwO2'].includes(user?.user?.id)) {
        menu = hotelClientMenu;
      } else {
        menu = clientMenu;
      }
    }

    setMenuUtil(menu);
  }, [user, customer, i18n.language]);

  const maximizeHandler = (e: React.MouseEvent, text: TMaxText) => {
    e.preventDefault();
    if (!minimize) return;
    if (text === "enter") {
      setMaximize(true);
    }
    if (text === "leave") {
      setMaximize(false);
    }
  };

  const minClass = minimize ? "minimize" : "";
  const maxClass = maximize ? "maximize" : "";

  return (
    <>
      <StyledBackdrop $show={show} onClick={displayHandler} />
      <StyledAside className={cn(minClass, maxClass)} $minimize={minimize} $mdMinimize={mdMinimize.current} $maximize={maximize} $show={show}>
        <Header minimizeHandler={minimizeHandler} displayHandler={displayHandler} minimize={minimize} mdMinimize={mdMinimize.current} show={show} sidebarLayout={sidebarLayout} />
        <StyledBody $minimize={minimize} $mdMinimize={mdMinimize.current} $maximize={maximize} $show={show} className="aside-body" onMouseEnter={(e) => maximizeHandler(e, "enter")} onMouseLeave={(e) => maximizeHandler(e, "leave")}>
          <Scrollbar>
            <StyledBodyInner className="aside-body-inner">
              <AsideUser />
              <NavbarAside menus={menuUtil} />
            </StyledBodyInner>
          </Scrollbar>
        </StyledBody>
      </StyledAside>
    </>
  );
};

export default Aisde;
