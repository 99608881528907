import React from "react";
import { IconProps } from "types";

const VenuesIcon: React.FC<IconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_1_299)">
      <path
        d="M18.7279 9.60554L10.3945 1.27221C10.2904 1.16874 10.1496 1.11066 10.0029 1.11066C9.8561 1.11066 9.7153 1.16874 9.61121 1.27221L1.27787 9.60554C1.18686 9.71182 1.1393 9.84853 1.1447 9.98835C1.1501 10.1282 1.20806 10.2608 1.30701 10.3597C1.40595 10.4587 1.53858 10.5166 1.6784 10.522C1.81822 10.5274 1.95493 10.4799 2.06121 10.3889L10.0001 2.44999L17.939 10.3944C18.0453 10.4854 18.182 10.533 18.3218 10.5276C18.4616 10.5222 18.5942 10.4642 18.6932 10.3653C18.7921 10.2664 18.8501 10.1337 18.8555 9.99391C18.8609 9.85409 18.8133 9.71738 18.7223 9.6111L18.7279 9.60554Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
      <path
        d="M15.5555 17.7777H12.7777V12.2222H7.22214V17.7777H4.44436V9.99997L3.33325 11.1111V17.7777C3.33325 18.0724 3.45032 18.3551 3.65869 18.5634C3.86706 18.7718 4.14968 18.8889 4.44436 18.8889H8.33325V13.3333H11.6666V18.8889H15.5555C15.8502 18.8889 16.1328 18.7718 16.3411 18.5634C16.5495 18.3551 16.6666 18.0724 16.6666 17.7777V10.9777L15.5555 9.86664V17.7777Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_299">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default VenuesIcon;
