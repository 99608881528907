import React from "react";
import { IconProps } from "types";

const IntegrationsIcon: React.FC<IconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M7.15567 13.87L7.86366 13.15L5.71366 11L7.86366 8.85L7.15567 8.13L4.28566 11L7.15567 13.87ZM11.9557 13.87L14.8257 11L11.9557 8.13L11.2477 8.85L13.3977 11L11.2477 13.15L11.9557 13.87ZM3.17066 19C2.724 19 2.343 18.8427 2.02766 18.528C1.713 18.2133 1.55566 17.8323 1.55566 17.385V4.615C1.55566 4.16833 1.713 3.78733 2.02766 3.472C2.34233 3.15733 2.72333 3 3.17066 3H7.87066C7.78 2.49 7.90233 2.029 8.23767 1.617C8.573 1.20567 9.01233 1 9.55567 1C10.1123 1 10.5583 1.20567 10.8937 1.617C11.2283 2.029 11.344 2.49 11.2407 3H15.9407C16.3873 3 16.7683 3.15733 17.0837 3.472C17.3983 3.78667 17.5557 4.16767 17.5557 4.615V17.385C17.5557 17.8317 17.3983 18.2127 17.0837 18.528C16.769 18.8427 16.388 19 15.9407 19H3.17066ZM3.17066 18H15.9407C16.094 18 16.235 17.936 16.3637 17.808C16.4917 17.6793 16.5557 17.5383 16.5557 17.385V4.615C16.5557 4.46167 16.4917 4.32067 16.3637 4.192C16.235 4.064 16.094 4 15.9407 4H3.17066C3.01733 4 2.87633 4.064 2.74766 4.192C2.61966 4.32067 2.55566 4.46167 2.55566 4.615V17.385C2.55566 17.5383 2.61966 17.6793 2.74766 17.808C2.87633 17.936 3.01733 18 3.17066 18ZM9.55567 3.442C9.77233 3.442 9.95167 3.37133 10.0937 3.23C10.2357 3.08867 10.3063 2.90933 10.3057 2.692C10.3057 2.47533 10.2347 2.29633 10.0927 2.155C9.95066 2.01367 9.77167 1.94267 9.55567 1.942C9.339 1.942 9.16 2.013 9.01867 2.155C8.87733 2.297 8.80633 2.476 8.80566 2.692C8.80566 2.90867 8.87667 3.088 9.01867 3.23C9.16067 3.372 9.33966 3.44267 9.55567 3.442Z"
      fill={color}
    />
    <path
      d="M2.55566 18V4M7.15567 13.87L7.86366 13.15L5.71366 11L7.86366 8.85L7.15567 8.13L4.28566 11L7.15567 13.87ZM11.9557 13.87L14.8257 11L11.9557 8.13L11.2477 8.85L13.3977 11L11.2477 13.15L11.9557 13.87ZM3.17066 19C2.724 19 2.343 18.8427 2.02766 18.528C1.713 18.2133 1.55566 17.8323 1.55566 17.385V4.615C1.55566 4.16833 1.713 3.78733 2.02766 3.472C2.34233 3.15733 2.72333 3 3.17066 3H7.87066C7.78 2.49 7.90233 2.029 8.23767 1.617C8.573 1.20567 9.01233 1 9.55567 1C10.1123 1 10.5583 1.20567 10.8937 1.617C11.2283 2.029 11.344 2.49 11.2407 3H15.9407C16.3873 3 16.7683 3.15733 17.0837 3.472C17.3983 3.78667 17.5557 4.16767 17.5557 4.615V17.385C17.5557 17.8317 17.3983 18.2127 17.0837 18.528C16.769 18.8427 16.388 19 15.9407 19H3.17066ZM3.17066 18H15.9407C16.094 18 16.235 17.936 16.3637 17.808C16.4917 17.6793 16.5557 17.5383 16.5557 17.385V4.615C16.5557 4.46167 16.4917 4.32067 16.3637 4.192C16.235 4.064 16.094 4 15.9407 4H3.17066C3.01733 4 2.87633 4.064 2.74766 4.192C2.61966 4.32067 2.55566 4.46167 2.55566 4.615V17.385C2.55566 17.5383 2.61966 17.6793 2.74766 17.808C2.87633 17.936 3.01733 18 3.17066 18ZM9.55567 3.442C9.77233 3.442 9.95167 3.37133 10.0937 3.23C10.2357 3.08867 10.3063 2.90933 10.3057 2.692C10.3057 2.47533 10.2347 2.29633 10.0927 2.155C9.95066 2.01367 9.77167 1.94267 9.55567 1.942C9.339 1.942 9.16 2.013 9.01867 2.155C8.87733 2.297 8.80633 2.476 8.80566 2.692C8.80566 2.90867 8.87667 3.088 9.01867 3.23C9.16067 3.372 9.33966 3.44267 9.55567 3.442Z"
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);

export default IntegrationsIcon;
