/* eslint-disable */
import React from "react";
/* eslint-disable import/no-unresolved */
import SidebarHeader from "src/components/apps/chat/sidebar-header";

const LoggedinUser: React.FC = () => {
    return <SidebarHeader />;
};

export default LoggedinUser;
