import React from "react";
import { IconProps } from "types";

const DashboardIcon: React.FC<IconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.78317 0.666687H5.59984C6.77484 0.666687 7.7165 1.62502 7.7165 2.80085V5.64169C7.7165 6.82502 6.77484 7.77502 5.59984 7.77502H2.78317C1.6165 7.77502 0.666504 6.82502 0.666504 5.64169V2.80085C0.666504 1.62502 1.6165 0.666687 2.78317 0.666687ZM2.78317 10.2248H5.59984C6.77484 10.2248 7.7165 11.1756 7.7165 12.3589V15.1998C7.7165 16.3748 6.77484 17.3331 5.59984 17.3331H2.78317C1.6165 17.3331 0.666504 16.3748 0.666504 15.1998V12.3589C0.666504 11.1756 1.6165 10.2248 2.78317 10.2248ZM15.2166 0.666687H12.3999C11.2249 0.666687 10.2833 1.62502 10.2833 2.80085V5.64169C10.2833 6.82502 11.2249 7.77502 12.3999 7.77502H15.2166C16.3833 7.77502 17.3333 6.82502 17.3333 5.64169V2.80085C17.3333 1.62502 16.3833 0.666687 15.2166 0.666687ZM12.3999 10.2248H15.2166C16.3833 10.2248 17.3333 11.1756 17.3333 12.3589V15.1998C17.3333 16.3748 16.3833 17.3331 15.2166 17.3331H12.3999C11.2249 17.3331 10.2833 16.3748 10.2833 15.1998V12.3589C10.2833 11.1756 11.2249 10.2248 12.3999 10.2248Z"
      fill={color}
    />
  </svg>
);

export default DashboardIcon;
